import { observer } from "mobx-react";
import styled from "styled-components";
import { Container } from "../../shared";

const Text = styled.p`
font-size: 24px;
line-height: 1.44;
  span {
    color: #c6230a;
    font-weight: bold;
  }
  @media(max-width: 500px) {
    font-size: 16px;
  }
`;

const SGText = observer(() => {
  return (
    <Container style={{display: "flex", flexDirection: "column", gap: 25, marginBottom: 20}}>
      <Text>
        <span>MATRIX SG</span> - Метод работы в энергоинформационном поле, основан на возможности человека получить информацию вне времени и пространства и перезаписать энергетическую структуру
        события или состояния человека.
      </Text>
      <Text>
      Мы используем данный метод в своей работе с клиентами уже много лет и готовы передать уникальные техники работы с подсознанием своим ученикам.  
      </Text>
      <Text>Метод, к сожалению, доступен не всем. И необходима изначальная диагностика возможностей человека. Он не является сложным для освоения, но это не игрушка и не должен использоваться не обдуманно. </Text>
      <Text><b>Обучение проходит больше на практической основе, минимум теории.</b></Text>
      <Text>Мы идём с учениками как проводники, объясняем происходящее и даём методики проработки точек кризиса человека. Открываем возможности работы с человеком через состояние транса, проработки заявленной проблемы.  </Text>
      <Text>Мы используем <span>MATRIX SG</span> когда проводим медитации в группах, но формат их не соответствует общепринятым понятиям. Нет звука, идёт погружение человека в  определённое состояние на 4 минуты. Не больше. Это подобно первой фазе поверхностного сна, пока человек не уходит в иллюзии и приглушил сознание.</Text>
      <Text>Эффект таких сессий заключается в нахождении проблемы и включении заблокированного ресурса человека.  </Text>
      <Text><i>*Как правило человек обращаясь с проблемой называет только видимые ему обстоятельства. Но в 95% случаев, он даже не подозревает об истинном положении дел. Корневая проработка ситуации не происходит, и ситуация решается лишь поверхностно. Кого-то удовлетворяет результат и он двигается дальше, но есть ситуации которые продолжают влиять на человека, его семью и дела. В этом случае есть возможность запросить реальную информацию и скорректировать в энерго информационном поле. </i></Text>
      <Text>Данный метод расширяет экстрасенсорные способности.</Text>
    </Container>
  );
});

export default SGText;
